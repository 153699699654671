@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
tit
============================================*/
.c-tit {
    font-family: v.$font_en-title;
    font-size: 15rem;
    font-weight: 500;
    letter-spacing: .05em;
    opacity: 0;
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 6rem;
        letter-spacing: .015em;
    }

    span {
        display: inline-block;
    }
}
