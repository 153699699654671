@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
contact
============================================*/
.p-top-contact {
    padding: 7.5rem 0 11.2rem;
    background: #f1f1f1;

    @include mx.mq('sp') {
        padding: 4rem 0 5.9rem;
    }
}
.p-top-contact__head {
    text-align: right;
    overflow: hidden;

    @include mx.mq('sp') {
        text-align: left;
    }

    .c-tit {
        margin-right: -20px;

        @include mx.mq('sp') {
            margin-left: -7px;
            margin-right: 0;
        }
    }
}
.p-top-contact .c-tit--vertical {
    @include mx.mq('sp') {
        left: unset;
        right: 2.5rem;
    }
}
.p-top-contact__txtArea {
    text-align: center;
    margin-top: 6.3rem;

    @include mx.mq('sp') {
        text-align: left;
    }
}
.p-top-contact__txt {
    letter-spacing: .08em;
    line-height: 2.5;
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.2rem;
        letter-spacing: .04em;
        line-height: 2;
    }

    span {
        display: inline-block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }

    a {
        display: inline-block;
        position: relative;
        transition: opacity .3s;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: .4rem;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 1px;
            background: #818283;

            @include mx.mq('sp') {
                bottom: 0;
            }
        }
    }
}
.p-top-contact__address {
    margin-top: 7.6rem;
    text-align: center;

    @include mx.mq('sp') {
        margin-top: 4.8rem;
    }
}
.p-top-contact__address__inner {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        display: inline-block;
        width: 4.8rem;
        margin-right: 2.8rem;

        @include mx.mq('sp') {
            width: 3.5rem;
            margin-right: 2.3rem;
        }
    }

    & > span {
        display: inline-block;
        overflow: hidden;
    }

    span span {
        display: inline-block;
        font-family: v.$font_en-title;
        font-size: 2.8rem;
        font-weight: 700;
        letter-spacing: .1em;
        background: linear-gradient(125deg, #a09573 0%, #b6a981 22%, #ccbf98 44%, #e0d6b8 51%, #ccbf98 57%, #b6a981 80%, #a8996c 100%);
        background-clip: text;
        color: transparent;
        padding-bottom: 3px;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }

        @include mx.mq('sp') {
            font-size: 2.1rem;
            letter-spacing: .05em;
        }
    }
}
.p-top-contact__form {
    padding: 0 3rem;
    margin: 10.3rem auto 0;

    @include mx.mq('sp') {
        padding: 0;
        margin: 6.2rem auto 0;
    }
}
.p-form__item {
    display: flex;
    align-items: center;

    @include mx.mq('sp') {
        display: block;
    }

    &:nth-child(n+2) {
        margin-top: 4rem;

        @include mx.mq('sp') {
            margin-top: 2.5rem;
        }
    }

    &:nth-child(5) dd {
        @include mx.mq('sp') {
            display: flex;
            flex-wrap: wrap;
            margin-top: 3rem;

        }
        label {
            @include mx.mq('sp') {
                width: calc(50% - 1.5rem);
                white-space: nowrap;
            }

            &:nth-child(3) {
                @include mx.mq('sp') {
                    margin-left: 0;
                }
            }

            &:nth-child(n+3) {
                @include mx.mq('sp') {
                    margin-top: 2.2rem;
                }
            }
        }
    }

    &:last-child {
        align-items: flex-start;

        @include mx.mq('sp') {
            margin-top: 4.5rem;
        }
    }
}
.p-form__item dt {
    width: 14rem;

    @include mx.mq('sp') {
        width: 9.7rem;
        font-size: 1.2rem;
        letter-spacing: .05em;
    }
}
.p-form__item dd {
    width: calc(100% - 19rem);
    margin-left: 5rem;

    @include mx.mq('sp') {
        width: 100%;
        margin: 1.5rem 0 0;
    }

    label {
        position: relative;

        &:nth-child(n+2) {
            margin-left: 4rem;

            @include mx.mq('sp') {
                margin-left: 3rem;
            }
        }

        &:nth-child(3) {
            @include mx.mq('sp') {
                margin-left: 0;
            }
        }
    }

    input {
        @include mx.mq('sp') {
            padding: .6em .8em;
        }
    }
}
.p-form__item__label,
.p-form__item__label--required {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    letter-spacing: .1em;
}
[type=radio] + span {
    font-weight: 500;
    letter-spacing: .1em;

    @include mx.mq('sp') {
        font-size: 1.2rem;
        letter-spacing: .05em;
        padding: 0 0 0 3.7rem;
    }
}
[type=radio] + span::before {
    @include mx.mq('sp') {
        width: 2.3rem;
        height: 2.3rem;
    }
}
[type=radio] + span::after {
    @include mx.mq('sp') {
        left: 5px;
        transform: translateY(-30%) rotate(-45deg);
        border-bottom: 2px solid #333637;
        border-left: 2px solid #333637;
    }
}
.p-form__textarea {
    @include mx.mq('sp') {
        height: 150px;
    }
}
.p-top-contact__form__submit {
    margin-top: 9.8rem;
    text-align: center;

    @include mx.mq('sp') {
        margin-top: 4.9rem;
    }

    .c-btn {
        color: v.$base_color;
        cursor: pointer;
        text-align: left;
    }
}
@media (hover: hover) and (pointer: fine) {
	.p-top-contact__txt a:hover {
        opacity: .8;
    }
}
@media screen and (min-width: 768px) and (max-width: 1100px) {
    .p-form__item dd label:nth-child(n+2) {
        margin-left: 2.5rem;
    }
    [type=radio] + span {
        padding: 0 0 0 4rem;
    }
}
@media screen and (max-width: 374px) {
    .p-top-contact__txt {
        font-size: 3.3vw;
    }
    [type=radio] + span {
        font-size: 1.1rem;
        padding: 0 0 0 3rem;
    }
}
