@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
instagram
============================================*/
.p-top-instagram {
    padding: 10.1vw 0;
    overflow: hidden;

    @include mx.mq('sp') {
        padding: 11.5rem 0 10rem;
    }
}
.p-top-instagram__inner {
    position: relative;
    transform: translateX(6.75vw);

    @include mx.mq('sp') {
        transform: translateX(0);
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -3.37vw;
        left: 10rem;
        width: 100%;
        height: calc(100% + 4.59vw);
        background: linear-gradient(320deg, #fed879 0%, #ef7837 10%, #c64570 30%, #98389c 70%, #7b3cb0 90%, #5e59c9 100%);
        z-index: -1;  

        @include mx.mq('sp') {
            left: 5rem;
            bottom: -2.5rem;
            height: calc(100% + 4rem);
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: calc(-3.37vw + 3px);
        left: calc(10rem + 3px);
        width: 100%;
        height: calc(100% + 4.59vw - 6px);
        background: #fff;
        z-index: -1;

        @include mx.mq('sp') {
            bottom: calc(-2.5rem + 2px);
            left: calc(5rem + 2px);
            height: calc(100% + 4rem - 4px);
        }
    }
}
.p-top-instagram__head .c-tit {
    margin-left: -5px;
}
.p-top-instagram__cont {
    display: flex;
    position: relative;
    padding: 3.3vw 1.96vw;
    margin-top: -36px;
    background: #3e3e3e;

    @include mx.mq('sp') {
        padding: 2.5rem 1rem 2.5rem 1.4rem;
        margin-top: -16px;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -2vw;
        left: 5rem;
        width: 10rem;
        height: calc(100% + 10rem + 2vw);
        background: #fff;
        z-index: -1;

        @include mx.mq('sp') {
            left: 2rem;
            width: 5rem;
            height: calc(100% + 4.7rem);
        }
    }
}
.p-top-instagram__intro {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-width: calc(382 / 1422 * 100%);
    padding: 1.62vw 1.96vw 2.27vw;
    border: 2px solid #a1a1a1;
    text-align: center;

    @include mx.mq('sp') {
        min-width: calc(384 / 700 * 100%);
        padding: 1rem 1.1rem 1.7rem;
        border: 1px solid #a1a1a1;
    }
}
.p-top-instagram__intro__txt {
    font-size: 1.08vw;
    color: #fff;
    letter-spacing: .1em;
    line-height: calc(30 / 16);
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.2rem;
        letter-spacing: .05em;
        line-height: calc(40 / 24);
    }

    span {
        display: inline-block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
.p-top-instagram__intro__img {
    margin-top: 1.48vw;

    @include mx.mq('sp') {
        margin-top: 1.1rem;
    }

    img {
        width: 3.37vw;

        @include mx.mq('sp') {
            width: 2.5rem;
        }
    }
}
.p-top-instagram__intro__btn {
    margin-top: 2vw;

    @include mx.mq('sp') {
        margin-top: 1rem;
    }
}
.p-top-instagram__intro__btn .c-btn--white {
    font-family: v.$base_font;
    font-size: min(1.08vw, 1.6rem);
    text-align: left;

    @include mx.mq('sp') {
        width: 11rem;
        font-size: 1.2rem;
    }
}
.p-top-instagram__posts {
    display: flex;

    @include mx.mq('sp') {
        width: 45.1%;
    }
}
.p-top-instagram__post {
    width: 17.56vw;
    margin-left: 2vw;

    @include mx.mq('sp') {
        width: calc(100% - 1.5rem);
        margin-left: 1.5rem;
    }

    a {
        display: block;
        padding: .67vw;
        background: #fff;

        @include mx.mq('sp') {
            padding: .5rem;
        }
    }

    &:nth-child(n+2) {
        @include mx.mq('sp') {
            display: none;
        }
    }

    &:last-child a {
        pointer-events: none;
    }
}
.p-top-instagram__post__imgBox {
    overflow: hidden;

    img {
        transition: transform .6s;
    }
}
@media (hover: hover) and (pointer: fine) {
	.p-top-instagram__post a:hover img {
        transform: scale(1.2);
    }
}
@media screen and (min-width: 768px) {
    .p-top-instagram__intro__btn .c-btn--white svg {
        width: min(2vw, 30px);
        height: min(2vw, 30px);
        bottom: max(-0.8vw, -12px);
        right: max(-0.67vw, -10px);
    }
}
@media screen and (max-width: 374px) {
    .p-top-instagram__intro__txt {
        font-size: 3.2vw;
    }
    .p-top-instagram__post {
        width: calc(100% - 1rem);
        margin-left: 1rem;
    }
    .p-top-instagram__intro__img {
        margin-top: .5rem;
    }
    .p-top-instagram__intro__btn {
        margin-top: .2rem;
    }
}