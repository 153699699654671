@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
partner
============================================*/
.p-top-partner {
    padding: 15rem 0 10rem;

    @include mx.mq('sp') {
        padding: 9rem 0 0;
    }
}
.p-top-partner__head {
    text-align: center;
}
.p-top-partner__head .c-tit {
    opacity: 1;
    overflow: hidden;

    span {
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1.5s ease-in-out;
        
        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
.p-top-partner .c-tit--vertical {
    left: unset;
    right: 10rem;

    @include mx.mq('sp') {
        right: 2.5rem;
    }
}
.p-top-partner__txtArea {
    margin-top: 5.8rem;
    text-align: center;

    @include mx.mq('sp') {
        margin-top: 5.7rem;
    }
}
.p-top-partner__txt {
    color: #323c40;
    letter-spacing: .1em;
    line-height: calc(50 / 16);
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.3rem;
        letter-spacing: .04em;
        line-height: calc(60 / 26);
    }

    &:nth-child(6) {
        margin-top: 5.2rem;

        @include mx.mq('sp') {
            margin-top: 3.1rem;
        }
    }

    span {
        display: inline-block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
.p-top-partner__btnArea {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 4.8rem 0 6rem;
    margin-top: 13.3rem;

    @include mx.mq('sp') {
        padding: 1.4rem 0 2.6rem;
        margin-top: 6.7rem;
    }


    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(1240 / 1480 * 100%);
        height: 100%;
        background: #f1f1f1;

        @include mx.mq('sp') {
            width: calc(350 / 375 * 100%);
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 5rem;
        right: 0;
        width: calc(1240 / 1480 * 100%);
        height: 100%;
        background: #cbcbcb;
        z-index: -1;

        @include mx.mq('sp') {
            top: 2.5rem;
            width: calc(350 / 375 * 100%);
        }
    }

    .c-btn {
        @include mx.mq('sp') {
            width: 11rem;
        }
    }
}
.p-top-partner__btnArea__btn:nth-child(2) {
    margin-left: 10rem;

    @include mx.mq('sp') {
        margin-left: 3rem;
    }
}
@media screen and (max-width: 374px) {
    .p-top-partner__txt {
        font-size: 3.4vw;
    }
}
