@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
contact-label
============================================*/
.c-contact-label {
    display: grid;
    place-items: center;
    font-size: 1.3rem;
    font-weight: 400;
    width: 6rem;
    height: 2.5rem;
    background: #686868;
    color: #fff;

    @include mx.mq('sp') {
        font-size: 1rem;
        width: 4.5rem;
        height: 1.9rem;
    }
}
.c-contact-label--required {
    @extend .c-contact-label;
    background: #9b915a;
}
