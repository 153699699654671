@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
label
============================================*/
.c-label {
    display: inline-block;
    font-family: v.$font_en;
    font-style: italic;
    font-size: min(1.5rem, 1.2vw);
    letter-spacing: .08em;
    color: #fff;
    text-transform: uppercase;
    padding: .6rem min(1.8rem, 1.2vw) .3rem;
    margin-left: 1.8rem;
    transform-origin: left;
    transform: translate3d(0,100%,0) skewY(12deg);
    transition: 1s ease-in-out;

    &.u-smoothTextAppear01 {
        transform: translate3d(0,0,0) skewY(0);
    }

    @include mx.mq('sp') {
        font-size: 1.2rem;
        padding: .6rem 1.6rem .3rem;
        margin-left: 1.3rem;
    }

    &.blog {
        background: #577c8d;
    }

    &.news {
        background: #9b915a;
    }
}
