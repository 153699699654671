@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
news
============================================*/
.p-top-news {
    padding: 11.3rem 0 11.2rem;
    background: #6b6b6b;
    overflow: hidden;

    @include mx.mq('sp') {
        padding: 4rem 0 6rem;
    }
}
.p-top-news__head {
    color: #a1a1a1;
    text-align: right;
    overflow: hidden;
}
.p-top-news__head .c-tit {
    margin-right: -20px;

    @include mx.mq('sp') {
        margin-right: -7px;
    }

    span span {
        padding: 0 .1em;

        @include mx.mq('sp') {
            padding: 0 .15em;
        }
    }
}
.p-top-news .c-tit--vertical span {
    color: #d7d7d7;

    &::before {
        background: #d7d7d7;
    }
}
.p-top-news__article__wrapper {
    display: flex;
    transform: translateX(calc(240 / 1480 * 100%));
    margin-top: -34px;

    @include mx.mq('sp') {
        width: 272vw;
        transform: translateX(20vw);
        margin-top: -15px;
    }
}
.p-top-news__article {
    width: calc(300 / 1480 * 100%);

    @include mx.mq('sp') {
        width: calc(240 / 1020 * 100%);
    }

    &:not(:first-child) {
        margin-left: 4rem;

        @include mx.mq('sp') {
            margin-left: 2rem;
        }
    }

    a {
        display: block;
    }
}
.p-top-news__article__inner {
    height: 100%;
    background: #fff;
    border-radius: 0 0 0 10px;
    box-shadow: 0px 20px 43px rgba(51, 54, 55, 0.25);
    padding: 2rem 0 3.2rem 2rem;

    @include mx.mq('sp') {
        border-radius: 0 0 0 5px;
        padding: 1.5rem 0 2.7rem 1.5rem;
    }
}
.p-top-news__article__img {
    position: relative;
    padding-top: calc(210 / 280 * 100%);
    overflow: hidden;

    @include mx.mq('sp') {
        padding-top: 75.6%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform .6s;
    }
}
.p-top-news__article__meta {
    display: flex;
    padding-left: 1rem;
    margin-top: 1.8rem;
    overflow: hidden;

    @include mx.mq('sp') {
        margin-top: 1.5rem;
    }

    time {
        display: inline-block;
        font-family: v.$font_en-title;
        font-size: min(2rem, 1.6vw);
        font-weight: 600;
        letter-spacing: .05em;
        vertical-align: text-top;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }

        @include mx.mq('sp') {
            font-size: 1.7rem;
            vertical-align: top;
        }
    }
}
.p-top-news__article__tit {
    font-size: min(1.8rem, 1.2vw);
    font-weight: 400;
    letter-spacing: .03em;
    line-height: calc(30 / 18);
    padding-left: 1rem;
    padding-right: 3rem;
    margin-top: 1.4rem;
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.45rem;
        margin-top: 1rem;
    }

    span {
        display: inline-block;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
.p-top-news__btnArea {
    margin-top: 8rem;
    transform: translateX(calc(240 / 1480 * 100%));

    @include mx.mq('sp') {
        margin-top: 4rem;
        transform: translateX(20vw);
    }
}
@media (hover: hover) and (pointer: fine) {
    .p-top-news__article__inner > a:hover {
        .p-top-news__article__img img {
            transform: scale(1.2);
        }
    }
}
@media screen and (max-width: 350px) {
    .p-top-news__head .c-tit {
        font-size: 5.7rem;
    }
}
