@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
under-tit
============================================*/
.c-under-tit {
    position: relative;
    font-family: v.$font_en-title;
    font-size: 15rem;
    font-weight: 500;
    letter-spacing: .03em;
    text-align: right;
    padding: 5rem 0;
    opacity: 0;
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 6rem;
        letter-spacing: .015em;
        padding: 3.2rem 0;
    }

    span {
        display: inline-block;
        background-position: center center;
        background-size: cover;
        background-clip: text;
        color: rgba(255, 255, 255, .6);
    }
}
