@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
service
============================================*/
.p-top-service {
    position: relative;
    background: url(../img/service_bg_pc.jpg) no-repeat center center / cover;
    padding: 10rem 0 9.3rem;
    margin-top: 21.8rem;

    @include mx.mq('sp') {
        background: url(../img/service_bg_sp.jpg) no-repeat center center / cover;
        padding: 7.5rem 0 6.9rem;
        margin-top: 10rem;
    }
}
.p-top-service .c-tit--vertical {
    left: unset;
    right: 10rem;

    @include mx.mq('sp') {
        right: 2.5rem;
    }
}
.p-top-service .c-tit--vertical span {
    color: #fff;

    &::before {
        background: #fff;
    }
}
.p-top-service__head {
    position: absolute;
    bottom: calc(100% - 53px);
    left: 0;
    overflow: hidden;

    @include mx.mq('sp') {
        bottom: calc(100% - 21px);
    }
}
.p-top-service__head .c-tit {
    margin-left: -20px;

    @include mx.mq('sp') {
        margin-left: -10px;
    }

    span {
        background: linear-gradient( to bottom, #8a7e57 65%, #fff 65%);
        background-clip: text;
        color: transparent;
    }
}
.p-top-service__items {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;

    @include mx.mq('sp') {
        display: block;
        padding: 0 1.5rem;
    }
}
.p-top-service__item {
    width: calc(280 / 920 * 100%);

    @include mx.mq('sp') {
        width: 100%;
    }

    &:nth-child(2) {
        padding-top: 8rem;

        @include mx.mq('sp') {
            padding-top: 0;
        }
    }

    &:nth-child(n+2) {
        @include mx.mq('sp') {
            margin-top: 2.9rem;
        }
    }
}
.p-top-service__item__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28rem;
    height: 28rem;
    background: #fff;
    border-radius: 50%;
    box-shadow: 5px 7px 13px rgba(0, 0, 0, 0.3);
    opacity: 0;

    @include mx.mq('sp') {
        width: 21rem;
        height: 21rem;
        margin: 0 auto;
    }
}
.p-top-service__item:nth-child(1) img {
    width: 46.4%;
}
.p-top-service__item:nth-child(2) img {
    width: 36.4%;
}
.p-top-service__item:nth-child(3) img {
    width: 66%;
}
.p-top-service__item__txtArea {
    color: #fff;
    margin-top: 3rem;

    @include mx.mq('sp') {
        margin-top: 2.4rem;
    }
}
.p-top-service__item__tit {
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: .08em;
    text-align: center;
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.8rem;
    }
    
    &.u-smoothTextAppear02 {
        animation-delay: .8s;

        @include mx.mq('sp') {
            animation-delay: .4s;
        }
    }

    span {
        display: inline-block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
.p-top-service__item__txt {
    letter-spacing: .1em;
    line-height: calc(30 / 16);
    margin-top: 2.1rem;
    text-align: justify;
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.3rem;
        line-height: calc(50 / 26);
        margin-top: 1.7rem;
    }
    
    &.u-smoothTextAppear02 {
        animation-delay: .8s;

        @include mx.mq('sp') {
            animation-delay: .4s;
        }
    }

    span {
        display: inline-block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
    .p-top-service .c-tit--vertical {
        right: 3rem;
    }
}
@media screen and (min-width: 768px) and (max-width: 1040px) {
    .p-top-service__item__img {
        width: 26.9vw;
        height: 26.9vw;
    }
}