@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
thanks
============================================*/
.thanks-page {
    padding: 10rem 0;

    @include mx.mq('sp') {
        padding: 15rem 0;
        margin-top: 6rem;
    }
}
.p-thanks {
    text-align: center;
}
.p-thanks__txt {
    line-height: 2;

    @include mx.mq('sp') {
        font-size: 1.5rem;
    }
}
.thanks-page + .l-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    .l-footer__sp-link {
        @include mx.mq('sp') {
            display: block !important;
        }
    }
}
.under-thanks-page section > div:last-child {
    padding: 15rem 0;

    @include mx.mq('sp') {
        padding: 5rem 0;
    }
}
.under-thanks-page + .l-footer {
    @include mx.mq('sp') {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .l-footer__sp-link {
        @include mx.mq('sp') {
            display: block !important;
        }
    }
}
.under-thanks-page + .l-footer.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
}
