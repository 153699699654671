@charset "utf-8";

@use './variable' as v;
@use './mixin' as mx;

html {
  font-size: 62.5%;

  @include mx.mq('tab') {
    font-size: 1.02vw;
  }
}

body{
  font-family: v.$base_font;
  font-size: 1.6em;
  font-weight: 400;
  color: v.$base_color;
}
