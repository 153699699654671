@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
footer
============================================*/
.l-footer {
    padding: 4.8rem 0 0;
    background: #686868;

    @include mx.mq('sp') {
        padding: 0;
        margin-bottom: 3.9rem;
    }
}
.l-footer__nav {
    @include mx.mq('sp') {
        display: none;
    }
}
.l-footer__nav__items {
    display: flex;
    justify-content: center;

    &:last-child {
        margin-top: 4.4rem;
    }
}
.l-footer__nav__item {
    &:not(:first-child) {
        border-left: 1px solid #fff;
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        letter-spacing: 0.085em;
        color: #fff;
        padding: .05rem 4rem;
    }
}
.l-footer__sub-cont {
    padding: 2rem 0;
    margin-top: 10rem;
    border-top: 1px solid #c1b797;

    @include mx.mq('sp') {
        display: none;
    }

    ul {
        display: flex;
        align-items: center;
    }
}
.l-footer__sub-cont__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.l-footer__sub-cont__items {
    margin-left: -3.5rem;
}
.l-footer__sub-cont__item:last-child {
    border-left: 1px solid #fff;
}
.l-footer__sub-cont__item a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: 0.05em;
    color: #fff;
    padding: 0.05rem 3.5rem;
}
.l-footer__sns li:last-child {
    margin-left: 3rem;
}
.l-footer__sns li a {
    display: inline-block;
    transition: opacity .3s;

    &:hover {
        opacity: .8;
    }
}
.l-footer__sns li:first-child a img {
    width: 3rem;
}
.l-footer__sns li:last-child a svg {
    width: 3.3rem;
    fill: #fff;
}
.l-footer__copyright {
    background: #333;
    padding: .8rem 0;
    text-align: center;

    @include mx.mq('sp') {
        background: #6b6b6b;
        padding: 2.15rem 0;
    }

    small {
        font-family: v.$font_en-title;
        font-size: 1.6rem;
        letter-spacing: 0.05em;
        color: #cec5a7;

        @include mx.mq('sp') {
            font-size: 1.3rem;
            letter-spacing: 0.025em;
            color: #fff;
        }

        span:first-child {
            padding-left: .5rem;

            @include mx.mq('sp') {
                padding-left: .4rem;
            }
        }

        span:last-child {
            font-size: 1.3rem;
            padding-left: 1.2rem;

            @include mx.mq('sp') {
                font-size: 1.1rem;
                padding-left: .7rem;
            }
        }
    }
}
.l-footer__sp-link {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: map-get(v.$layer, "sp-footer");
}
.l-footer__sp-link ul {
    @include mx.mq('sp') {
        display: flex;
    }
}
.l-footer__sp-link ul li {
    width: 33.3333%;

    &:nth-child(n+2) {
        @include mx.mq('sp') {
            border-left: 1px solid #fff;
        }
    }
    
    a {
        display: block;
        font-family: v.$font_en-title;
        font-size: 1.1rem;
        font-weight: 700;
        letter-spacing: .025em;
        background: v.$base_color;
        color: #b3b3b3;
        text-align: center;
        text-transform: uppercase;
        padding: 1.4rem;
    }
}
@media (hover: hover) and (pointer: fine) {
	.l-footer__nav__item a:hover span:nth-child(1),
    .l-footer__sub-cont__item a:hover span:nth-child(1) {
		animation: nav-hover02 .4s .03s;
	}
	.l-footer__nav__item a:hover span:nth-child(2),
    .l-footer__sub-cont__item a:hover span:nth-child(2) {
		animation: nav-hover02 .4s .06s;
	}
	.l-footer__nav__item a:hover span:nth-child(3),
    .l-footer__sub-cont__item a:hover span:nth-child(3) {
		animation: nav-hover02 .4s .09s;
	}
	.l-footer__nav__item a:hover span:nth-child(4),
    .l-footer__sub-cont__item a:hover span:nth-child(4) {
		animation: nav-hover02 .4s .12s;
	}
	.l-footer__nav__item a:hover span:nth-child(5),
    .l-footer__sub-cont__item a:hover span:nth-child(5) {
		animation: nav-hover02 .4s .15s;
	}
	.l-footer__nav__item a:hover span:nth-child(6),
    .l-footer__sub-cont__item a:hover span:nth-child(6) {
		animation: nav-hover02 .4s .18s;
	}
	.l-footer__nav__item a:hover span:nth-child(7),
    .l-footer__sub-cont__item a:hover span:nth-child(7) {
		animation: nav-hover02 .4s .21s;
	}
	.l-footer__nav__item a:hover span:nth-child(8),
    .l-footer__sub-cont__item a:hover span:nth-child(8) {
		animation: nav-hover02 .4s .24s;
	}
	.l-footer__nav__item a:hover span:nth-child(9),
    .l-footer__sub-cont__item a:hover span:nth-child(9) {
		animation: nav-hover02 .4s .27s;
	}
	.l-footer__nav__item a:hover span:nth-child(10),
    .l-footer__sub-cont__item a:hover span:nth-child(10) {
		animation: nav-hover02 .4s .30s;
	}
	
    @keyframes nav-hover02{
        0%{ color: #fff;}
        35%,65%{ color: #bbad82;}
        100%{ color: #fff;}
    }
}
