@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
to-contact
============================================*/
.c-to-contact {
    display: grid;
    place-items: center;
    position: absolute;
    top: 2.2rem;
    right: 2.6rem;
    width: 26rem;
    height: 26rem;
    background: #fff;
    border-radius: 50%;

    @include mx.mq('sp') {
        display: none;
    }

    a {
        display: grid;
        place-items: center;
        position: relative;
        width: 22rem;
        height: 22rem;
        border-radius: 50%;
        transition: opacity .3s;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url(../img/contact-circle.png) no-repeat center center / contain;
            animation: rotation 30s linear infinite;
        }
    }
}
@keyframes rotation{
    0%{ transform:rotate(0);}
    100%{ transform:rotate(360deg); }
}
@media (hover: hover) and (pointer: fine) {
	.c-to-contact a:hover {
		opacity: .8;
	}
}
