@charset "utf-8";

// フォント
$base_font: 'Noto Sans JP', sans-serif;
$sub_font: 'Noto Serif JP', serif;
$font_en: 'Josefin Sans', sans-serif;
$font_en-title: 'Cormorant', serif;

// カラー
$base_color: #333637; //ベーステキスト

// レイアウト幅
$layout-width-inner: 980px;
$layout-max-width-inner: 1480px;

// メディアクエリ
$breakpoints: (
  'sp': 'only screen and (max-width: 767px)', //スマホ用
  'tab': 'only screen and (min-width: 768px) and (max-width: #{$layout-width-inner - 1px})', //tab用
  'pc': 'only screen and (min-width: #{$layout-width-inner})', //pc用
  'retina': 'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)' //retina対応
) !default;

//z-index
$layer: (
  drawer: 400,
  sp-menu: 300,
  header: 200,
  sp-footer: 100,
  default: 1
);