@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
container
============================================*/
.l-container {
  width: 90%;
  max-width: v.$layout-width-inner;
  margin: 0 auto;

  @include mx.mq('sp') {
    width: 100%;
    padding: 0 2.5rem;
  }
}
