@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
header
============================================*/
.l-header--onlyTopPage {
    padding-right: 2rem;
    margin-bottom: 10rem;
    overflow: hidden;

    @include mx.mq('sp') {
        display: none;
    }
}
.l-header--onlyTopPage .l-header__gnav {
    transform-origin: left;
    transform: translate3d(0,100%,0) skewY(12deg);
    transition: 1.5s ease-in-out;

    &.u-smoothTextAppear01 {
        transform: translate3d(0,0,0) skewY(0);
    }
}
.l-header--onlyTopPage .l-header__gnav__inner {
    display: flex;
    position: relative;
    padding-left: 23.5rem;

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 19rem;
        height: 1px;
        background: v.$base_color;
    }
}
.l-header--onlyTopPage .l-header__gnav__item {
    &:nth-child(2) {
        margin-left: 2rem;
    }

    &:nth-child(3) {
        margin-left: 2.6rem;

        @include mx.mq('tab') {
            margin-left: 2rem;
        }
    }

    &:nth-child(4) {
        margin-left: 1.8rem;
    }

    &:nth-child(5) {
        margin-left: 1.7rem;
    }

    &:nth-child(6) {
        margin-left: 1.8rem;
    }

    &:nth-child(7) {
        margin-left: 1.9rem;
    }

    a {
        display: inline-block;
        font-family: v.$font_en;
        font-style: italic;
        font-size: 2rem;
        font-weight: 400;
        letter-spacing: .085em;
        padding: 0.5rem;
    }
}
.l-header--fixed {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: map-get(v.$layer, "header");

    @include mx.mq('sp') {
        display: block;
        background: transparent;
    }
}
.l-header__inner {
    display: flex;
    align-items: center;
    position: relative;
    padding: min(4rem, 2.7vw);
    overflow: hidden;

    @include mx.mq('sp') {
        display: none;
    }
}
.l-header__logo {
    width: min(16rem, 10.8vw);

    a {
        display: block;
        transition: opacity .3s;
    }
}
.l-header__nav {
    margin-left: min(3.8rem, 2.56vw);
}
.l-header__nav__inner {
    display: flex;
    position: relative;
    padding-left: min(20rem, 13.5vw);

    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: min(16rem, 10.8vw);
        height: 1px;
        background: #333637;
    }
}
.l-header__nav__item:nth-child(2) {
    margin-left: min(3rem, 2vw);
}
.l-header__nav__item:nth-child(3) {
    margin-left: min(3.6rem, 2.43vw);
}
.l-header__nav__item:nth-child(n+4) {
    margin-left: min(2.8rem, 1.89vw);
}
.l-header__nav__item a {
    display: inline-block;
    font-family: v.$font_en;
    font-style: italic;
    font-size: min(2rem, 1.35vw);
    letter-spacing: .085em;
    padding: .5rem;
}
.l-header .c-to-contact {
    top: -4rem;
    right: -3rem;
    width: min(18rem, 12.1vw);
    height: min(18rem, 12.1vw);
    background: none;

    a {
        width: min(17.6rem, 11.9vw);
        height: min(17.6rem, 11.9vw);

        &::before {
            width: 98%;
            height: 98%;
        }

        img {
            width: min(7.6rem, 5.1vw);
        }
    }
}
.l-header__btn {
    position: fixed;
    top: 0;
    right: 0;
	width: 56px;
	height: 60px;
	background-image: linear-gradient( 145deg, #a09573 0%, #b6a981 22%, #d3c69e 37%, #ddd4b9 51%, #d3c69e 63%, #b6a981 80%, #a8996c 100%);
	cursor: pointer;
    z-index: map-get(v.$layer, "drawer");

    span {
        display: inline-block;
        position: absolute;
        left: 50%;
		transform: translateX(-50%);
		width: 20px;
        height: 1.5px;
        background: #fff;
	
		&:nth-child(1) {
		    top:19px;
            transform-origin: center center;
            transition: transform .5s;
		}
		
		&:nth-child(2) {
	        top:30px;
            transform: translateX(calc(-50% + 5px));
            transition: width .3s;
		}
		
		&:nth-child(3) {
		    top:40px;
            transition: transform .5s;
		}
	}
}
.l-header.open .l-header__btn span {
    &:nth-child(1) {
        transform: translate(-10px, 10px) rotate(225deg);
    }
    
    &:nth-child(2) {
        width: 0;
    }
    
    &:nth-child(3) {
        transform: translate(-10px, -10px) rotate(-225deg);
    }

}
.l-header__logo__sp {
    display: none;

    @include mx.mq('sp') {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background: #fff;
        padding-left: 1.5rem;
        z-index: map-get(v.$layer, "header");
    }

    a {
        display: inline-block;
        width: 10rem;
        padding-top: 1.5rem;
    }
}
.l-header--fixed .l-header__logo__sp {
    @include mx.mq('sp') {
        display: none;
        background: transparent;
    }
}
.l-header__sp-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    padding: 2.5rem 2.8rem;
    background: #fff;
    transform: translateY(-100%);
    transition: transform .5s;
    z-index: map-get(v.$layer, "sp-menu");
}
_::-webkit-full-page-media, _:future, :root .l-header__sp-menu {
    @include mx.mq('sp') {
        // height: calc(100vh - 88pt); //iPhoneアドレスバー対策
    }
}
.l-header.open .l-header__sp-menu {
    transform: translateY(0);
}
.l-header__sp-menu__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 2px solid v.$base_color;
    padding: 3.4vh 2.4rem 4.5vh;
}
.l-header__sp-menu .l-header__logo {
    width: 10rem;
    margin: 0 auto;
}
.l-header__sp-menu .l-header__nav {
    margin-left: 0;
}
.l-header__sp-menu .l-header__nav__inner {
    display: block;
    padding-left: 0;

    &::before {
        content: none
    }
}
.l-header__sp-menu .l-header__nav__item {
    border-top: 1px solid #c9c0a6;

    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(n+4) {
        margin-left: 0;
    }

    &:last-child {
        border-bottom: 1px solid #c9c0a6;
    }

    a {
        display: block;
        font-size: 2.1rem;
        padding: 2.4vh 1.7rem;
    }
}
.l-header__sp-menu .l-header__nav__btn ul {
    display: flex;
    flex-wrap: wrap;

    li:first-child {
        width: 100%;

        a {
            display: block;
            font-family: v.$font_en-title;
            font-size: 1.8rem;
            font-weight: 600;
            letter-spacing: .025em;
            color: #b3b3b3;
            background: v.$base_color;
            text-align: center;
            text-transform: uppercase;
            padding: 1.8vh 0;
        }
    }

    li:nth-child(n+2) {
        width: calc(50% - .75rem);
        height: 5.7vh;
        margin-top: 2.8vh;

        a {
            display: grid;
            place-items: center;
            height: inherit;
            background: #a79c7a;
        }
    }

    li:nth-child(2) a img {
        width: 2.3rem;
    }

    li:last-child {
        margin-left: 1.5rem;

        a img {
            width: 1.05rem;
        }
    }
}
.l-header__sp-menu .l-header__sub-cont ul {
    display: flex;
    justify-content: center;
    align-items: center;

    li a {
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 400;
        letter-spacing: .025em;
    }

    li:first-child {
        a {
            padding: .5rem 1.7rem .5rem 0;
        }
    }

    li:last-child {
        position: relative;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 5px;
            left: 0;
            width: 1.5px;
            height: 15px;
            background: v.$base_color;
        }

        a {
            padding: .5rem 0 .5rem 1.7rem;
        }

    }
}
@media (hover: hover) and (pointer: fine) {
    .l-header__logo a:hover {
        opacity: .8;
    }
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(1),
    .l-header__nav__item a:hover span:nth-child(1) {
		animation: nav-hover .4s .03s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(2),
	.l-header__nav__item a:hover span:nth-child(2) {
		animation: nav-hover .4s .06s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(3),
	.l-header__nav__item a:hover span:nth-child(3) {
		animation: nav-hover .4s .09s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(4),
	.l-header__nav__item a:hover span:nth-child(4) {
		animation: nav-hover .4s .12s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(5),
	.l-header__nav__item a:hover span:nth-child(5) {
		animation: nav-hover .4s .15s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(6),
	.l-header__nav__item a:hover span:nth-child(6) {
		animation: nav-hover .4s .18s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(7),
	.l-header__nav__item a:hover span:nth-child(7) {
		animation: nav-hover .4s .21s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(8),
	.l-header__nav__item a:hover span:nth-child(8) {
		animation: nav-hover .4s .24s;
	}
    .l-header--onlyTopPage .l-header__gnav__item a:hover span:nth-child(9),
	.l-header__nav__item a:hover span:nth-child(9) {
		animation: nav-hover .4s .27s;
	}
    @keyframes nav-hover{
        0%{ color: v.$base_color;}
        35%,65%{ color: #bbad82;}
        100%{ color: v.$base_color; }
    }
}
@media screen and (max-width: 374px) {
    .l-header__sp-menu .l-header__logo {
        width: 26.7vw;
    }
    .l-header__sp-menu__inner {
        padding: 3.4vh 2.4rem;
    }
    .l-header__sp-menu .l-header__nav__item a {
        font-size: 5.6vw;
    }
    .l-header__sp-menu .l-header__nav__btn ul li:first-child a {
        font-size: 4.8vw;
    }
    .l-header__sp-menu .l-header__nav__btn ul li:nth-child(2) a img {
        width: 6.1vw;
    }
    .l-header__sp-menu .l-header__nav__btn ul li:nth-child(3) a img {
        width: 2.9vw;
    }
    .l-header__sp-menu .l-header__sub-cont ul li a {
        font-size: 3.4vw;
    }
}
@media screen and (max-width: 1040px) {
    .l-header--onlyTopPage .l-header__gnav__inner {
        padding-left: 17rem;

        &::before {
            width: 13rem;
        }
    }
}
@media screen and (min-width: 768px) {
    .l-header__sp-menu {
        display: none;
    }
}
