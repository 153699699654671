@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
message
============================================*/
.p-top-message {
    padding: 10rem 0 15rem;

    @include mx.mq('sp') {
        padding: 7rem 0 7.5rem;
    }
}
.p-top-message__head {
    color: #c9c9c9;
}
.p-top-message__head .c-tit {
    margin-left: -14px;
    overflow: visible;

    @include mx.mq('sp') {
        margin-left: -6px;
    }
}
.p-top-message .c-tit--vertical {
    left: unset;
    right: 10rem;

    @include mx.mq('sp') {
        right: 2.5rem;
    }
}
.p-top-message__txtArea {
    text-align: center;
    margin-top: 9.3rem;

    @include mx.mq('sp') {
        margin-top: 6.4rem;
    }
}
.p-top-message__txt {
    font-size: 1.8rem;
    letter-spacing: .08em;
    line-height: calc(60 / 18);
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.3rem;
        letter-spacing: .04em;
        line-height: calc(60 / 26);
    }

    & > span {
        display: block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1.5s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }

    span span {
        display: inline-block;
        position: relative;
        color: #fff;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -48%);
            width: 100%;
            height: 1.67em;
            background: v.$base_color;
            z-index: -1;
        }
    }
}
.p-top-message__txt:nth-child(3) {
    @include mx.mq('sp') {
        margin-top: 2.7rem;
    }
}
.p-top-message__txt:nth-child(6) {
    @include mx.mq('sp') {
        margin-top: 3.1rem;
    }
}
.p-top-message__txt:last-child {
    @include mx.mq('sp') {
        margin-top: 3.1rem;
    }
}
.p-top-message__name {
    text-align: right;
    margin-top: 7.9rem;
    overflow: hidden;

    @include mx.mq('sp') {
        margin-top: 5.5rem;
    }
}
.p-top-message__name__txt {
    display: inline-block;
    position: relative;
    font-family: v.$sub_font;
    font-size: 2.2rem;
    font-weight: 500;
    letter-spacing: .02em;
    padding-bottom: 2.2rem;
    transform-origin: left;
    transform: translate3d(0,100%,0) skewY(12deg);
    transition: 1.5s ease-in-out .5s;

    @include mx.mq('sp') {
        font-size: 1.4rem;
        letter-spacing: .01em;
        padding-bottom: 1rem;
    }

    &.u-smoothTextAppear01 {
        transform: translate3d(0,0,0) skewY(0);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 0.08em);
        height: 2px;
        background-image : linear-gradient(to right, #929494, #929494 4px, transparent 4px, transparent 8px);
        background-size: 8px 2px;
        background-position: left bottom;
        background-repeat: repeat-x;

        @include mx.mq('sp') {
            background-image : linear-gradient(to right, #929494, #929494 2px, transparent 2px, transparent 4px);
        background-size: 4px 1px;
        }
    }

    span {
        margin-left: calc(3rem - .04em);

        @include mx.mq('sp') {
            margin-left: calc(1.9rem - .02em);
        }
    }
}
@media screen and (max-width: 374px) {
    .p-top-message__txt {
        font-size: 3.4vw;
    }
}