@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
mv
============================================*/
.l-mv {
    position: relative;
    height: 100vh;
    padding: 10rem;
    background: url(../img/mv_bg.png) no-repeat top left;
    background-size: 36% auto;

    @include mx.mq('sp') {
        padding: 2.5rem;
        background-size: 68% auto;
    }
}
_::-webkit-full-page-media, _:future, :root .l-mv {
    @include mx.mq('sp') {
        height: calc(100vh - 44pt); //iPhoneアドレスバー対策
    }
}
// @supports (-webkit-touch-callout: none) {
//     .l-mv {
//         @include mx.mq('sp') {
//             /* The hack for Safari */
//             height: -webkit-fill-available;
//         }
//     }
// }
.l-mv__inner {
    position: relative;
    height: 100%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 0;
        height: 3px;
        background: v.$base_color;
        animation: border-length01 .3s linear .5s forwards;

        @include mx.mq('sp') {
            height: 2px;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 3px;
        background: v.$base_color;
        animation: border-length01 .3s linear 1.1s forwards;

        @include mx.mq('sp') {
            height: 2px;
        }
    }

    @include mx.mq('sp') {
        height: 100%;
    }
}
@keyframes border-length01 {
    0% {width: 0%;}
    100% {width: 100%;}
}
.l-mv__border {
    position: relative;
    width: 100%;
    height: 100%;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 0;
        background: v.$base_color;
        animation: border-length02 .3s linear .8s forwards;

        @include mx.mq('sp') {
            width: 2px;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 3px;
        height: 0;
        background: v.$base_color;
        animation: border-length02 .3s linear 1.4s forwards;

        @include mx.mq('sp') {
            width: 2px;
        }
    }
}
@keyframes border-length02 {
    0% {height: 0%;}
    100% {height: 100%;}
}
.l-mv__tit {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 203px;

    @include mx.mq('sp') {
        width: 140px;
    }

    img {
        z-index: 100;
        opacity: 1;
    }

    svg {
        width: 100%;
        max-width: 203px;
        height: auto;
    }
}
#mask .st0{
    fill: none;
    stroke: #fff;/*マスクする線の色*/
    stroke-width: 5;/*線の太さを指定する*/
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 1000; /* 線の間隔を指定する */
    stroke-dashoffset: 1000; /* 線の位置を指定する */

    &:nth-child(1) {
        stroke-width: 2.5;/*線の太さを指定する*/
    }

    &:nth-child(2) {
        stroke-width: 4;/*線の太さを指定する*/
    }

    &:nth-child(3) {
        stroke-width: 4;/*線の太さを指定する*/
    }

    &:nth-child(4) {
        stroke-width: 3.5;/*線の太さを指定する*/
    }
}
