@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
tit--vertical
============================================*/
.c-tit--vertical {
    position: absolute;
    top: 0;
    left: 10rem;
    writing-mode: vertical-rl;
    overflow: hidden;

    @include mx.mq('sp') {
        left: 2.5rem;
    }
    
    span {
        display: inline-block;
        position: relative;
        font-family: v.$sub_font;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: .08em;
        padding-top: 12rem;
        transform-origin: bottom;
        transform: translate3d(100%,0,0) skewX(-12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewX(0);
        }

        @include mx.mq('sp') {
            font-size: 1.5rem;
            letter-spacing: .04em;
            padding-top: 6rem;
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 1px;
            height: 10rem;
            background: v.$base_color;

            @include mx.mq('sp') {
                height: 5rem;
            }
        }
    }
}
