@charset "utf-8";

@use './variable' as v;


// メディアクエリ
@mixin mq($breakpoint: sp) {
  @media #{map-get(v.$breakpoints, $breakpoint)} {
    @content;
  }
}
