@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
cmn page
============================================*/
.p-cmn-page__head {
    position: relative;
    padding: 7.5rem 0;

    @include mx.mq('sp') {
        padding: 3.75rem 0;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: calc(1240 / 1480 * 100%);
        height: 100%;
        border: 3px solid v.$base_color;
        border-left: none;
        z-index: -1;

        @include mx.mq('sp') {
            width: calc(700 / 750 * 100%);
            border: 2px solid v.$base_color;
            border-left: none;
        }
    }
}
.p-cmn-page__head__inner {
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(91, 95, 96, .9);
    }
}
.p-cmn-page__head__inner + .c-sns-vertical {
    top: calc(100% - 2.5rem);
    bottom: unset;
    z-index: 1;
}
.p-cmn-page-contact .p-top-contact__head {
    text-align: left;

    .c-tit {
        margin-left: -17px;

        @include mx.mq('sp') {
            margin-left: -9px;
        }
    }
}
.p-cmn-page-contact .c-tit--vertical {
    left: unset;
    right: 10rem;
}
