@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
works
============================================*/
.p-top-works {
    position: relative;
    padding: 11rem 0 16.2rem;
    background: #f6f6f6;

    @include mx.mq('sp') {
        padding: 7.5rem 0 13.5rem;
        margin-top: 5rem;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: -5rem;
        left: 0;
        width: 30rem;
        height: 18rem;
        background: url(../img/works.jpg) no-repeat center center / cover;
        box-shadow: 13px 17px 20px rgba(176, 176, 176, 0.3);

        @include mx.mq('sp') {
            bottom: -2.5rem;
            width: 15rem;
            height: 9rem;
        }
    }
}
.p-top-works__head {
    text-align: right;
    overflow: hidden;
}
.p-top-works__head .c-tit {
    margin-right: -25px;

    @include mx.mq('sp') {
        margin-right: -8px;
    }
}
.p-top-works__txtArea {
    text-align: center;
    margin-top: 6.3rem;

    @include mx.mq('sp') {
        margin-top: 6rem;
    }
}
.p-top-works__txt {
    color: #323c40;
    letter-spacing: .1em;
    line-height: 2.5;
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.3rem;
        letter-spacing: .05em;
        line-height: calc(60 / 26);
    }

    span {
        display: inline-block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
.p-top-works__deco {
    display: flex;
    justify-content: center;
    margin: 5.8rem 0;

    @include mx.mq('sp') {
        margin: 2.6rem 0;
    }

    span {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        background: v.$base_color;
        border-radius: 50%;

        @include mx.mq('sp') {
            width: .5rem;
            height: .5rem;
        }

        &:nth-child(n+2) {
            margin-left: 1rem;

            @include mx.mq('sp') {
                margin-left: .5rem;
            }
        }
    }
}
.p-top-works__btnArea {
    margin-top: 13.6rem;
    text-align: center;

    @include mx.mq('sp') {
        margin-top: 6.6rem;
    }
}
.p-top-works__btnArea .c-btn {
    text-align: left;
}
@media screen and (max-width: 374px) {
    .p-top-works__txt {
        font-size: 3.5vw;
    }
}