@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
ユーティリティ
============================================*/
.u-only-pc {
    @include mx.mq('pc') {
        display: block;
    }
    @include mx.mq('tab') {
        display: block;
    }
    @include mx.mq('sp') {
        display: none;
    }
}
.u-only-sp {
    @include mx.mq('pc') {
        display: none;
    }
    @include mx.mq('tab') {
        display: none;
    }
    @include mx.mq('sp') {
        display: block;
    }
}
.u-fadeInUp {
    animation: fadeInUpAnime .5s forwards;
}
@keyframes fadeInUpAnime {
    0%{opacity: 0; transform: translateY(100px);}
    100%{opacity: 1; transform: translateY(0);}
}
.u-delay02 {
    animation-delay: 0.2s;

    @include mx.mq('sp') {
        animation-delay: unset;
    }
}
.u-delay03 {
    animation-delay: 0.3s;

    @include mx.mq('sp') {
        animation-delay: unset;
    }
}
.u-delay04 {
    animation-delay: 0.4s;

    @include mx.mq('sp') {
        animation-delay: unset;
    }
}
.u-fadeInLeft {
    animation: fadeInLeftAnime 1s forwards;
}
@keyframes fadeInLeftAnime {
    0%{opacity: 0; transform: translateX(-100px);}
    100%{opacity: 1; transform: translateX(0);}
}
.u-fadeInRight {
    animation: fadeInRightAnime 1s forwards;
}
@keyframes fadeInRightAnime {
    0%{opacity: 0; transform: translateX(100px);}
    100%{opacity: 1; transform: translateX(0);}
}
.u-smoothTextAppear01 {
    transform: translate3d(0,0,0) skewY(0);
}
.u-smoothTextAppear02 {
    animation: smoothTextAppear02 1.5s forwards;
}
@keyframes smoothTextAppear02 {
    0%{opacity: 0; transform: translateY(100%);}
    100%{opacity: 1; transform: translateY(0);}
}
.u-smoothTextAppear03 {
    animation: smoothTextAppear03 1.5s forwards;
}
@keyframes smoothTextAppear03 {
    0%{opacity: 0; transform: translateY(-100%);}
    100%{opacity: 1; transform: translateY(0);}
}
.u-smoothTextAppear04 {
    animation: smoothTextAppear04 1.5s forwards;
}
@keyframes smoothTextAppear04 {
    0%{opacity: 0; transform: translateY(300%);}
    100%{opacity: 1; transform: translateY(0);}
}
.u-smoothTextAppear05 {
    animation: smoothTextAppear05 1.5s forwards;
}
@keyframes smoothTextAppear05 {
    0%{opacity: 0; transform: translateY(-300%);}
    100%{opacity: 1; transform: translateY(0);}
}
