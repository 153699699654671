@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
btn
============================================*/
.c-btn {
    display: inline-block;
    position: relative;
    width: 15.4rem;
    font-family: v.$font_en;
    font-style: italic;
    font-size: 2rem;
    letter-spacing: .05em;
    padding-bottom: 1.4rem;

    @include mx.mq('sp') {
        width: 12.5rem;
        font-size: 1.5rem;
        font-weight: 400;
        letter-spacing: .025em;
        padding-bottom: 1.3rem;
    }

    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 2px;
        background: v.$base_color;
        transition: width .3s;

        @include mx.mq('sp') {
            height: 1px;
        }
    }

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 3px;
        right: 0;
        width: 8px;
        height: 2px;
        background: v.$base_color;
        transform: rotate(45deg);

        @include mx.mq('sp') {
            bottom: 2px;
            width: 6px;
            height: 1px;
        }
    }
}
.c-btn--white {
    @extend .c-btn;
    color: #fff;

    &::before, &::after {
        background: #fff;
    }
}
.c-btn svg {
    position: absolute;
    bottom: -12px;
    right: -10px;

    @include mx.mq('sp') {
        bottom: -13px;
        right: -12px;
        transform: scale(.73);
    }
}
.c-btn__circle {
	transition: stroke-dashoffset .3s ease;
	stroke-dasharray: 95;
	stroke-dashoffset: 0;
}
.c-btn.disabled {
    opacity: 0;
    pointer-events: none;
}
@media (hover: hover) and (pointer: fine) {
	.c-btn:hover::before {
        width: 30%;
    }
    .c-btn:hover .c-btn__circle {
        stroke-dashoffset: 95;
    }
}
