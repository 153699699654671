@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
about
============================================*/
.p-top-about {
    position: relative;
    padding: 10rem 0 15rem;
    background: #f1f1f1;

    @include mx.mq('sp') {
        padding: 6.5rem 0 10rem;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -5rem;
        right: 0;
        width: 17rem;
        height: 30rem;
        background: url(../img/about_02.jpg) no-repeat center center / cover;
        box-shadow: 13px 17px 20px rgba(176, 176, 176, 0.3);

        @include mx.mq('sp') {
            bottom: -2.5rem;
            width: 8.5rem;
            height: 15rem;
        }
    }
}
.p-top-about__head {
    text-align: right;
    overflow: hidden;

    .c-tit {
        margin-right: -20px;

        @include mx.mq('sp') {
            margin-right: -7px;
        }
    }
}
.p-top-about__intro {
    display: flex;
    justify-content: space-between;
    width: 66.2vw;
    max-width: 980px;
    padding-right: calc(96 / 980 * 100%);
    margin: -27px auto 0;

    @include mx.mq('sp') {
        display: block;
        width: 100%;
        padding-right: 0;
        margin-top: 6.5rem;
    }
}
.p-top-about__intro__img {
    width: calc(288 / 884 * 100%);

    @include mx.mq('sp') {
        width: 49.2%;
        margin: 0 auto;
    }

    img {
        box-shadow: 13px 17px 20px rgba(176, 176, 176, 0.3);
    }
}
.p-top-about__intro__txtArea {
    width: calc(508 / 884 * 100%);
    padding-top: 6vw;

    @include mx.mq('sp') {
        width: 100%;
        text-align: center;
        padding-top: 3.1rem;
    }
}
.p-top-about__intro__txt {
    font-size: min(1.5rem, .98vw);
    font-feature-settings: "palt";
    letter-spacing: .1em;
    line-height: calc(36 / 15);
    overflow: hidden;

    @include mx.mq('sp') {
        font-size: 1.3rem;
        letter-spacing: .03em;
        line-height: calc(60 / 26);
    }

    &:nth-child(5),
    &:nth-child(6) {
        margin-top: 3.5rem;

        @include mx.mq('sp') {
            margin-top: 3.1rem;
        }
    }

    span {
        display: inline-block;
        transform-origin: left;
        transform: translate3d(0,100%,0) skewY(12deg);
        transition: 1s ease-in-out;

        &.u-smoothTextAppear01 {
            transform: translate3d(0,0,0) skewY(0);
        }
    }
}
.p-top-about__detail {
    position: relative;
    width: 61vw;
    max-width: 89.8%;
    padding: 2.5rem 5rem 3rem;
    margin: 9rem 5rem 0;
    margin: 9rem auto 0;
    background: #fff;
    box-shadow: 30px 38px 20px rgba(176, 176, 176, 0.3);

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: -10rem;
        left: calc(50% - 50vw);
        width: 17rem;
        height: 17rem;
        background: url(../img/about_01.jpg) no-repeat center center / cover;
        box-shadow: 13px 17px 20px rgba(176, 176, 176, 0.3);

        @include mx.mq('tab') {
            top: -7rem;
        }

        @include mx.mq('sp') {
            top: -6rem;
            left: 0;
            width: 8.4rem;
            height: 8.4rem;
        }
    }

    @include mx.mq('sp') {
        width: unset;
        max-width: unset;
        padding: 5rem 2.5rem 6.8rem;
        margin: 13rem -2.5rem 0;
    }
}
.p-top-about__detail__deco {
    position: absolute;
    top: -3rem;
    right: 3rem;
    font-family: v.$font_en;
    font-style: italic;
    font-size: 2.6rem;
    font-weight: 300;
    letter-spacing: .07em;
    color: #fff;
    writing-mode: vertical-rl;
    padding: 3.9rem 1.4rem;
    background: v.$base_color;

    @include mx.mq('sp') {
        top: -1.5rem;
        right: 2.5rem;
        font-size: 1.5rem;
        letter-spacing: .05em;
        padding: 2.4rem .8rem ;
    }
}
_::-webkit-full-page-media, _:future, :root .p-top-about__detail__deco {
    right: 5rem;

    @include mx.mq('sp') {
        right: 4rem;
    }
}
.p-top-about__detail dl {
    display: flex;
    padding: 2.2rem 3rem 1.8rem 1rem;

    @include mx.mq('sp') {
        display: block;
        padding: 1.3rem 0 1.1rem .6rem;
    }

    &:not(:first-of-type) {
        border-top: 1px solid #cacaca;
    }
}
.p-top-about__detail dl dt,
.p-top-about__detail dl dd {
    font-size: min(1.6rem, 1.2vw);
    line-height: 2.5;
    opacity: 0;
    overflow: hidden;

    @include mx.mq('sp') {
        line-height: 2.2;
    }

    span {
        display: inline-block;
    }
}
.p-top-about__detail dl:last-child dt {
    opacity: 1;

    & > span {
        opacity: 0;
        overflow: hidden;
    }
}
.p-top-about__detail dl dt {
    width: min(17.8rem, 12vw);
    font-weight: 500;
    letter-spacing: .08em;

    @include mx.mq('sp') {
        width: 100%;
        font-size: 1.4rem;
    }
}
.p-top-about__detail dl dd {
    width: calc(100% - min(17.8rem, 12vw));
    color: #5b6062;
    letter-spacing: .1em;
    font-feature-settings: "palt";

    @include mx.mq('sp') {
        width: 100%;
        font-size: 1.3rem;
        line-height: calc(50 / 26);
    }
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
    .p-top-about__detail::before {
        width: 13vw;
        height: 13vw;
    }
    .p-top-about .c-tit--vertical {
        left: 2rem;
    }
    .p-top-about::after {
        width: 13vw;
        height: 23vw;
    }
}
@media screen and (max-width: 374px) {
    .p-top-about__intro__txt {
        font-size: 3.3vw;
    }
}
