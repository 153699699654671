@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
pagination
============================================*/
.c-pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    @include mx.mq('sp') {
        justify-content: flex-end;
    }
}
.c-pagination ul {
    display: flex;
    align-items: center;
}
.page-numbers .prev,
.page-numbers .next {
    transition: opacity .3s;
}
.c-pagination__prev,
.c-pagination__next {
    position: relative;
    width: 3rem;
    color: transparent;
}
.c-pagination__prev {
    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3rem;
        height: 1px;
        background: #000;

        @include mx.mq('sp') {
            width: 2.5rem;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        left: -1px;
        width: 1rem;
        height: 1px;
        background: #000;
        transform: rotate(-45deg);

        @include mx.mq('sp') {
            width: .7rem;
        }
    }
}
.c-pagination__next {
    margin-left: 2rem;

    @include mx.mq('sp') {
        margin-left: 1.3rem;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3rem;
        height: 1px;
        background: #000;

        @include mx.mq('sp') {
            width: 2.5rem;
            left: .4rem;
        }
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 5px;
        right: 6px;
        width: 1rem;
        height: 1px;
        background: #000;
        transform: rotate(45deg);

        @include mx.mq('sp') {
            right: 4px;
            width: .7rem;
        }
    }
}
.page-numbers a {
    display: grid;
    place-items: center;
    width: 3rem;
    height: 3rem;
    font-family: v.$font_en;
    font-style: italic;
    font-size: 1.8rem;
    letter-spacing: .05em;
    color: #000;
    border: 1px solid #000;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: 1.5rem;
    transition: background .3s, color .3s;

    @include mx.mq('sp') {
        width: 2.7rem;
        height: 2.7rem;
        font-size: 1.6rem;
        margin-left: 1.3rem;
    }
}
.page-numbers a.prev,
.page-numbers a.next {
    display: inline-block;
    place-items: center;
    width: unset;
    height: unset;
    border: none;
    margin-left: 0;
}
.page-numbers span.current {
    display: grid;
    place-items: center;
    width: 3rem;
    height: 3rem;
    font-family: v.$font_en;
    font-style: italic;
    font-size: 1.8rem;
    letter-spacing: .05em;
    color: #fff;
    background: #000;
    border: 1px solid #000;
    border-radius: 50%;
    padding-top: 3px;
    margin-left: 1.5rem;

    @include mx.mq('sp') {
        width: 2.7rem;
        height: 2.7rem;
        font-size: 1.6rem;
        margin-left: 1.3rem;
    }
}
@media (hover: hover) and (pointer: fine) {
	.page-numbers a:hover {
		background: #000;
        color: #fff;
	}
    .page-numbers a.prev:hover,
    .page-numbers a.next:hover {
        background: transparent;
        color: transparent;
        opacity: .7;
    } 
}
@media screen and (max-width: 374px) {
    .page-numbers a {
        width: 2.2rem;
        height: 2.2rem;
    }
}
