@charset "utf-8";

@use '../foundation/variable' as v;
@use '../foundation/mixin' as mx;

/*============================================
sns-vertical
============================================*/
.c-sns-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 5rem;
    right: 2rem;

    @include mx.mq('sp') {
        display: none;
    }
}
.c-sns-vertical__txt {
    position: relative;
    font-family: v.$font_en;
    font-style: italic;
    font-size: 2rem;
    letter-spacing: .1em;
    padding-bottom: 10rem;
    text-transform: uppercase;
    writing-mode: vertical-rl;

    &::after {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1px;
        height: 8rem;
        background: v.$base_color;
    }
}
.c-sns-vertical__items {
    margin-top: 1.5rem;
}
.c-sns-vertical__item:last-child {
    margin-top: 1rem;
}
.c-sns-vertical__item a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.2rem;
    height: 4.2rem;
    background: v.$base_color;
    border-radius: 50%;
    transition: opacity .3s;

    &:hover {
        opacity: .8;
    }
}

.c-sns-vertical__item:first-child a img {
    width: 2rem;
}

.c-sns-vertical__item:last-child a img {
    width: 1.1rem;
}