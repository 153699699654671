@charset "utf-8";

@use '../foundation/variable' as v;

/*============================================
section
============================================*/
.l-section {
    position: relative;
}
